.header {
  width: 100%;
  background-color: rgb(var(--color-primary));
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0.5rem 1rem;
}

.appName {
  margin: 0 0 0 8px;
  font-weight: 700;
  font-size: 1.5rem;
  flex: 1;
}
